import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Title from "../ui-components/title/title";
import { FaLightbulb } from "react-icons/fa";
class Blog extends React.Component {
  state = {
    donts: [
      {
        id: "1",
        title: "No Religious Promotions",
        description:
          "Do not use the platform for promoting any religious agenda.",
        icon: FaLightbulb,
      },
      {
        id: "2",
        title: "No Political Agendas",
        description:
          "Do not use the platform for political agendas of any kind.",
        icon: FaLightbulb,
      },
      {
        id: "3",
        title: "No Hatred or Abusive Language",
        description:
          "Platform cannot be used for promoting hatred or using abusive language.",
        icon: FaLightbulb,
      },
    ],
  };

  render() {
    const { donts } = this.state;

    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <Title title="Don'ts" />
          <p className="font15">
            Things you should avoid doing on the platform
          </p>
          <Row className="padding30">
            {donts.map((dont) => (
              <Col xs={12} sm={6} lg={4} key={dont.id}>
                <Card className="mb-4">
                  <Card.Body>
                    <dont.icon size={30} className="mr-2" />
                    <Card.Title className="weight800 font18 padding10">{dont.title}</Card.Title>
                    <Card.Text className="font14">{dont.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  }
}

export default Blog;
