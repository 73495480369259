import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Player } from "video-react";
// SCSS
import "./hero.scss";
// Assets
import HeroVideo from "../../assets/hero/hero.mov";
// Components
import {
  AppStoreButton,
  ButtonsContainer,
  GooglePlayButton,
} from "react-mobile-app-button";
import "video-react/dist/video-react.css";

const hero = () => {
  const APKUrl =
    "https://drive.google.com/file/d/1S3f4gATy_mhrlhvmycdbjrUNPutPkKg5/view?usp=sharing";
  const iOSUrl = "";

  return (
    <div className="hero" id="hero">
      <div className="wrapper">
        <Row middle="sm">
          <Col md={12} lg={6}>
            <div className="hero-info">
              <h1 className="weight500 font20" style={{ paddingBottom: 6, paddingLeft: 3 }}>
                MyVolunteerWorld
              </h1>
              <h1 className="weight800 font60 hero-subtitle">
                Your Efforts, Our Collective Future
              </h1>
              <p className="font13">
                MyVolunteerWorld is a trust-based platform that enables
                volunteers to log their social work and generate “Effort
                Currency” that will be redeemable within the ecosystem.
              </p>
              <ButtonsContainer>
                <GooglePlayButton
                  url={APKUrl}
                  theme={"dark"}
                  className={"download-btn-custom-style"}
                />
                <AppStoreButton
                  url={iOSUrl}
                  theme={"dark"}
                  className={"download-btn-custom-style"}
                />
              </ButtonsContainer>
            </div>
          </Col>
          <Col md={12} lg={6} style={{ marginTop: "3rem" }}>
            <div className="hero-video">
              <Player
                className="video-player"
                src={HeroVideo}
                loop
                fluid={true}
                muted={true}
                autoPlay={true}
                width="100%"
                height="auto"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default hero;
