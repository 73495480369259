import React from "react";
// SCSS
import "./blog.scss";
// Assets
import Preview01 from "../../assets/blog/story01/4.jpeg";
import Preview02 from "../../assets/blog/story02/5.jpeg";
import Preview03 from "../../assets/blog/story03/6.jpeg";
// Components
import Title from "../ui-components/title/title";
import BlogBox from "./blogBox";
import { Row, Col } from "react-bootstrap";

class Blog extends React.Component {
  state = {
    stories: [
      {
        image: Preview01,
        id: "1",
        title: "Individual Volunteers",
        description:
          "People who are passionate about making a positive impact but need support to scale their efforts and achieve the change they aspire to.",
      },
      {
        image: Preview02,
        id: "2",
        title: "NGOs",
        description:
          "Non-governmental organizations seeking to expand their reach, amplify their social impact, and connect with like-minded contributors.",
      },
      {
        image: Preview03,
        id: "3",
        title: "CSR Departments",
        description:
          "CSR departments of organisations that want transparency in spend and wish to drive ESG as a practice in theirs",
      },
    ],
  };

  render() {
    const { stories } = this.state;

    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <Title title="Who Should Sign Up?" />
          <p className="font15">
            Who should sign up, what will form the DNA of this platform
          </p>
          <Row className="padding30">
            {stories.map((story) => (
              <Col xs={12} sm={6} lg={4} key={story.id}>
                <BlogBox article={story} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  }
}

export default Blog;
