import React from "react";
import { FaBullseye, FaLightbulb } from "react-icons/fa"; 
import "./teamInfo.scss";

const TeamInfo = ({ title, description }) => (
  <div className="team__info flex-center">
    <div className="team__info-content">
      {title === "Our Mission" ? (
        <FaBullseye className="team__info-icon" />
      ) : (
        <FaLightbulb className="team__info-icon" />
      )}
      <h4 className="font20 weight800">{title}</h4>
      <p className="font14 weight500">{description}</p>
    </div>
  </div>
);

export default TeamInfo;
