import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Title from "../ui-components/title/title";
import {
  FaHandsHelping,
  FaLeaf,
  FaHeart,
  FaHandHoldingHeart,
  FaGratitude,
  FaLightbulb,
  FaUserShield,
  FaPeopleCarry,
} from "react-icons/fa";

class Values extends React.Component {
  state = {
    values: [
      {
        id: "1",
        title: "Trust",
        // icon: FaUserShield,
      },
      {
        id: "2",
        title: "Kindness",
        // icon: FaHeart,
      },
      {
        id: "3",
        title: "Empathy to Fellow Beings",
        // icon: FaHandsHelping,
      },
      {
        id: "4",
        title: "Sensitivity to Nature",
        // icon: FaLeaf,
      },
      {
        id: "5",
        title: "Solution-Based Approach",
        // icon: FaLightbulb,
      },
      {
        id: "6",
        title: "Gratitude",
        // icon: FaGratitude,
      },
      {
        id: "7",
        title: "Selflessness",
        // icon: FaHandHoldingHeart,
      },
      {
        id: "8",
        title: "Upliftment",
        // icon: FaPeopleCarry,
      },
    ],
  };

  render() {
    const { values } = this.state;

    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <Title title="Our Values" />
          <p className="font15">Core principles we stand by</p>
          <Row className="padding30">
            {values.map((value) => (
              <Col xs={12} sm={6} lg={3} key={value.id}>
                <Card className="mb-4 text-center shadow-sm">
                  <Card.Body>
                    <value.icon size={40} className="text-primary mb-3" />
                    <Card.Title className="font-weight-bold font18">
                      {value.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  }
}

export default Values;
