import React from "react";
// SCSS
import "./blog.scss";
// Assets
import Preview01 from "../../assets/blog/story01/1.jpeg";
import Preview02 from "../../assets/blog/story02/2.jpeg";
import Preview03 from "../../assets/blog/story03/3.jpeg";
// Components
import Title from "../ui-components/title/title";
import BlogBox from "./blogBox";
import { Row, Col } from "react-bootstrap";

class Blog extends React.Component {
  state = {
    stories: [
      {
        image: Preview01,
        id: "1",
        title: "Like-Minded Community",
        description:
          "Connect with people who share your values and believe in making a difference, working together to create a better world.",
      },
      {
        image: Preview02,
        id: "2",
        title: "Amplify Social Impact",
        description:
          "Access organized tools and resources that help you multiply your social impact, making it easier to contribute effectively.",
      },
      {
        image: Preview03,
        id: "3",
        title: "Faster Change",
        description:
          "The more volunteers that join, the quicker we can achieve a truly self-sustainable social network, driving meaningful change.",
      },
    ],
  };

  render() {
    const { stories } = this.state;

    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <Title title="Why Joins Us?" />
          <p className="font15">Empowering Today, Securing Tomorrow</p>
          <Row className="padding30">
            {stories.map((story) => (
              <Col xs={12} sm={6} lg={4} key={story.id}>
                <BlogBox article={story} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  }
}

export default Blog;
