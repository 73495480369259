import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";
// Assets

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="About Us" />
      <p className="font15">
        With the advancement of technology, there are huge changes that our
        world is experiencing in our social fabric, climate, environment, work
        culture and other aspects of life. Families are becoming smaller, people
        are getting lonelier, life span is increasing, jobs are getting
        automated, the economic divide is increasing, the work-life imbalance is
        rising, rural-to-urban migration is constantly increasing, the climate
        is changing for worse, and there is a huge burden on governments. The
        sheer size and population of countries like India don't make it feasible
        for governments to implement an efficient and well-governed social
        security system. The COVID experience taught us that there is an
        inherent goodness in people. Many want to give back to society and have
        a community of people who can come together to help.
      </p>
      <p className="font15">
        That's where MyVolunteerWorld comes in. MyVolunteerWorld is a
        trust-based ecosystem platform that enables volunteers to log their
        social work and generate “Effort Currency” that will be redeemable
        within the ecosystem.
      </p>
      <p className="font15">
        While the currency is a point-based virtual system, it will bring
        real-world benefits. Full-time and part-time volunteers can secure this
        currency instead of the time and effort they spend doing small or big
        volunteering tasks to make our world a better place.
      </p>
      <Row center="sm" className="padding40">
        <Col md={12} lg={5}>
          <TeamInfo
            title="Our Mission"
            description="To drive social change by fostering an environment where people live in harmony with each other and create a platform that allows you to build social safety nets and safeguards for old age."
          />{" "}
        </Col>
        <Col md={12} lg={5}>
          <TeamInfo
            title="Our Vision"
            description="To help build a more inclusive, self-sustainable and socially conscious society"
          />
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
